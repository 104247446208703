<template>
  <input
    autofocus
    class="w-0 p-0 fixed"
    @keydown.enter="send($event.target.value)"
  />
  <div class="fixed top-4 right-4 text-right">
    <span v-if="lastKeepAlive" class="bg-white p-1 font-bold">{{
      lastKeepAlive.toLocaleTimeString("de-DE")
    }}</span
    ><br />
    <button v-if="$route.query.monitor !== undefined" @click="openWebSocket()">
      <font-awesome-icon
        icon="arrows-rotate"
        class="mx-2 text-2xl inline-block"
      />
    </button>
  </div>
  <div
    v-if="displayMessage.status"
    class="w-full h-full text-center absolute z-30"
    :class="bg"
  >
    <div class="w-2/3 inline-block text-4xl underline mt-3 font-bold">
      <span class="block">{{ displayMessage.status }}</span>
      <div class="w-full inline-block mt-0">
        <div v-if="displayMessage.name" class="flex flex-row text-3xl">
          <span class="w-full text-left flex-1">Name:</span>
          <span class="w-full text-right flex-grow">{{
            displayMessage.name
          }}</span>
        </div>
        <div v-if="displayMessage.class" class="flex flex-row text-3xl">
          <span class="w-full text-left flex-1">Klasse:</span>
          <span class="w-full text-right flex-grow">{{
            displayMessage.class
          }}</span>
        </div>
        <span v-if="displayMessage.lastSeen" class="text-4xl inline-block"
          >Zuletzt registriert:<br />{{ displayMessage.lastSeen }}</span
        >
        <div v-if="displayMessage.main" class="mt-2 bg-white rounded-lg">
          <span class="text-4xl py-4 inline-block">{{
            displayMessage.main
          }}</span>
        </div>
        <div v-if="displayMessage.variation" class="bg-white rounded-lg">
          <span class="text-4xl py-4 inline-block"
            >({{ displayMessage.variation }})</span
          >
        </div>
        <div v-if="displayMessage.dessert" class="mt-2 bg-white rounded-lg">
          <span class="text-4xl py-4 inline-block">{{
            displayMessage.dessert
          }}</span>
        </div>
        <div
          v-if="displayMessage.veggie"
          class="mt-2 bg-white rounded-lg px-4 pb-4 pt-2 inline-block"
        >
          <font-awesome-icon icon="leaf" class="mx-2 text-4xl inline-block" />
          <span class="text-4xl inline-block">Vegetarisch</span>
          <font-awesome-icon icon="leaf" class="mx-2 text-4xl inline-block" />
        </div>
      </div>
    </div>
  </div>
  <div v-if="mensa" class="w-full h-full text-center">
    <div
      v-if="remaining.meal"
      class="bg-white rounded-lg w-1/2 inline-block mt-20 p-4 text-lg font-bold"
    >
      <span class="text-2xl font-bold mb-4">Verbleibende Essen</span>
      <div v-for="meal in Object.keys(remaining.meal)" :key="meal">
        <div class="flex flex-row">
          <span class="w-full text-left flex-grow ">{{ meal }}</span>
          <span class="w-full text-right flex-1 ">{{
            remaining.meal[meal]
          }}</span>
        </div>
      </div>
      <br />
      <div v-for="dessert in Object.keys(remaining.dessert)" :key="dessert">
        <div class="flex flex-row">
          <span class="w-full text-left flex-grow ">{{ dessert }}</span>
          <span class="w-full text-right flex-1 ">{{
            remaining.dessert[dessert]
          }}</span>
        </div>
      </div>
    </div>
    <img
      v-else-if="smallDisplay || Object.keys(week).length == 0"
      class="inline-block max-w-full max-h-full"
      :src="require('@/assets/mensen/' + mensa + '.png')"
    />
    <div class="w-full h-full px-2" v-else>
      <span class="text-3xl font-bold py-3 inline-block">Speiseplan</span>
      <div class="bg-white rounded-lg py-4 px-4 w-full mt-2 text-left">
        <div
          v-for="day in Object.keys(week)"
          :key="day"
          class="border-b border-black"
        >
          <div>
            <span class="font-bold"
              >{{ day }}, {{ getWeekDateForWeekday(day) }}</span
            >
            <div v-if="week[day].closed">
              <span class="inline-block ml-10 py-2 font-bold"
                >Mensa geschlossen: {{ week[day].closingReason }}</span
              >
            </div>
            <div v-else>
              <div
                v-for="meal in week[day].meals"
                :key="meal"
                class="inline-block"
              >
                <div
                  class="shadow-lg p-2 rounded-xl m-2 max-w-72 inline-block border-2"
                  :class="
                    week[day].defaults.main &&
                    week[day].defaults.main === meal.main
                      ? 'border-green-600'
                      : ''
                  "
                  v-if="
                    meal.main != '' &&
                    week[day].defaults.main &&
                    week[day].defaults.main === meal.main
                  "
                >
                  <div class="w-full h-full flex flex-row">
                    <font-awesome-icon
                      v-if="meal.mainType === 'Schwein'"
                      icon="piggy-bank"
                      class="text-secondary align-top mt-2 pr-2 text-4xl"
                    />
                    <font-awesome-icon
                      v-else-if="meal.mainType === 'Rind'"
                      icon="cow"
                      class="text-secondary align-top mt-2 pr-2 text-4xl"
                    />
                    <font-awesome-icon
                      v-else-if="meal.mainType === 'Fisch'"
                      icon="fish-fins"
                      class="text-secondary align-top mt-2 pr-2 text-4xl"
                    />
                    <font-awesome-icon
                      v-else-if="meal.mainType === 'Geflügel'"
                      icon="drumstick-bite"
                      class="text-secondary align-top mt-2 pr-2 text-4xl"
                    />
                    <img
                      src="@/assets/lamb.png"
                      v-else-if="meal.mainType === 'Lamm'"
                      class="text-secondary align-top mt-1 pr-2 w-12"
                    />
                    <font-awesome-icon
                      v-else-if="meal.mainType === 'Vegetarisch'"
                      icon="leaf"
                      class="text-secondary align-top mt-2 pr-2 text-4xl"
                    />
                    <font-awesome-icon
                      v-else
                      icon="utensils"
                      class="text-secondary align-top mt-2 pr-2 text-4xl"
                    />
                    <div class="inline-block">
                      <div class="border-b border-gray-700">
                        <span class="font-bold">{{ meal.main }}</span>
                      </div>
                      <div>
                        <div v-if="meal.variations.length > 0" class="pt-2">
                          <div
                            v-for="variation in meal.variations"
                            :key="variation"
                            class="inline-block"
                          >
                            <div
                              v-if="
                                editMode ||
                                week[day].defaults.variation == variation
                              "
                              :class="
                                week[day].defaults.main &&
                                week[day].defaults.main === meal.main &&
                                week[day].defaults.variation == variation
                                  ? 'border-green-600'
                                  : ''
                              "
                              class="font-bold px-2 py-1 m-1 border-2 bg-gray-100 rounded-lg inline-block"
                            >
                              {{ variation }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span
                v-if="week[day].meals[0].main == ''"
                class="inline-block ml-10"
                >Keine Gerichte auswählbar</span
              >
              <div
                class="inline-block shadow-lg p-2 rounded-xl m-2 align-top"
                v-if="week[day].desserts.length > 0"
              >
                <font-awesome-icon
                  icon="ice-cream"
                  class="text-secondary align-top mt-2 text-4xl"
                />
                <div class="inline-block">
                  <div>
                    <div class="pt-2">
                      <div
                        v-for="dessert in week[day].desserts"
                        :key="dessert"
                        class="inline-block"
                      >
                        <div
                          v-if="
                            editMode || week[day].defaults.dessert === dessert
                          "
                          class="font-bold px-2 py-1 m-1 border-2 bg-gray-100 rounded-lg inline-block"
                          :class="
                            week[day].defaults.dessert === dessert
                              ? 'border-green-500'
                              : ''
                          "
                        >
                          {{ dessert }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="$route.query.monitor === undefined"
      class="absolute bottom-5 left-5 bg-white rounded-lg shadow-lg cursor-pointer p-5 z-10"
      @click="startQRScan()"
    >
      <font-awesome-icon icon="qrcode" class="text-5xl inline-block" /><br />
      <span class="text-xl font-bold">Code Scannen</span>
    </div>
    <div
      v-else
      class="absolute bottom-5 left-5 bg-white rounded-lg shadow-lg cursor-pointer p-5 z-10"
      @click="showKeyboard = !showKeyboard"
    >
      <font-awesome-icon icon="keyboard" class="text-5xl inline-block" /><br />
      <span class="text-xl font-bold">Tastatur öffnen</span>
    </div>
    <div v-show="qrscan" class="absolute w-full h-full top-0 left-0">
      <div class="flex items-center justify-center w-full h-full z-10">
        <video
          id="video"
          ref="video"
          class="w-96 h-96 max-h-96"
          style="-webkit-transform: scaleX(-1); transform: scaleX(-1)"
          autoplay
        ></video>
      </div>
    </div>
    <div class="w-full fixed bottom-40">
      <div class="w-full relative">
        <div
          id="keyboard"
          class="inline-block bg-white p-4 rounded-lg shadow-xl z-10 w-3/5 scale-150 relative"
          v-if="showKeyboard"
          @click="keyPress($event.target)"
        >
          <div class="w-full px-10 whitespace-normal">
            <button
              :key="result.id"
              v-for="result in manualSearchResults"
              @click="sendManual(result)"
              class="rounded-lg m-1"
            >
              {{ result.name }}
            </button>
          </div>
          <div>
            <input
              v-model="manualSearch"
              style="display: inline-block; margin-top: 10px"
              placeholder="Name"
            />
          </div>
          <div class="row">
            <div class="key">^</div>
            <div class="key">1</div>
            <div class="key">2</div>
            <div class="key">3</div>
            <div class="key">4</div>
            <div class="key">5</div>
            <div class="key">6</div>
            <div class="key">7</div>
            <div class="key">8</div>
            <div class="key">9</div>
            <div class="key">0</div>
            <div class="key">ß</div>
            <div class="key">´</div>
            <div class="key wide">Backspace</div>
          </div>
          <div class="row">
            <div class="key wide">Tab</div>
            <div class="key">Q</div>
            <div class="key">W</div>
            <div class="key">E</div>
            <div class="key">R</div>
            <div class="key">T</div>
            <div class="key">Z</div>
            <div class="key">U</div>
            <div class="key">I</div>
            <div class="key">O</div>
            <div class="key">P</div>
            <div class="key">Ü</div>
            <div class="key">+</div>
            <div class="key">#</div>
          </div>
          <div class="row">
            <div class="key extra-wide">Caps Lock</div>
            <div class="key">A</div>
            <div class="key">S</div>
            <div class="key">D</div>
            <div class="key">F</div>
            <div class="key">G</div>
            <div class="key">H</div>
            <div class="key">J</div>
            <div class="key">K</div>
            <div class="key">L</div>
            <div class="key">Ö</div>
            <div class="key">Ä</div>
            <div class="key wide">Enter</div>
          </div>
          <div class="row">
            <div class="key">Y</div>
            <div class="key">X</div>
            <div class="key">C</div>
            <div class="key">V</div>
            <div class="key">B</div>
            <div class="key">N</div>
            <div class="key">M</div>
            <div class="key">,</div>
            <div class="key">.</div>
            <div class="key">-</div>
          </div>
          <div class="row">
            <div class="key" style="width: 200px">Space</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/API";
export default {
  name: "MensaView",
  data() {
    return {
      blockReading: false,
      manualSearchResults: [],
      manualSearch: "",
      lastTag: "",
      lastScan: new Date(),
      rfid: "",
      mensa: this.$router.currentRoute.value.name,
      displayMessage: {},
      bg: "bg-green-300/90",
      qrscan: false,
      showKeyboard: false,
      lastKeepAlive: new Date(),
      kaInterval: 0,
      week: {},
      smallDisplay: window.innerHeight < 800,
      remaining: {},
    };
  },
  methods: {
    sendManual(contract) {
      this.showKeyboard = false;
      this.manualSearchResults = [];
      this.manualSearch = "";
      this.send(contract.rfid);
    },
    keyPress(e) {
      if (e.className.includes("key")) {
        console.log(e.innerHTML);
        if (e.innerHTML == "Backspace") {
          this.manualSearch = this.manualSearch.slice(0, -1);
        } else if (e.innerHTML == "Enter") {
          this.searchForContract();
        } else {
          this.manualSearch += e.innerHTML;
        }
      }
    },
    searchForContract() {
      if (this.manualSearch) {
        API.getContractsWithName(
          this.$route.name,
          this.manualSearch,
          (r, status) => {
            if (status === 200) {
              this.manualSearchResults = r;
            }
          }
        );
      }
    },
    getWeekDateForWeekday(weekday) {
      let startDate = new Date();
      startDate.set;
      let offset =
        ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag"].indexOf(
          weekday
        ) + 1;
      let diff = offset - startDate.getDay();
      startDate.setDate(startDate.getDate() + diff);
      console.log(startDate);
      return startDate.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
      });
    },
    getMealPlan() {
      let vm = this;
      API.getMealPlanForMonitor(
        this.$router.currentRoute.value.name,
        (r, status) => {
          if (status === 200) {
            let anyMeal = false;
            Object.keys(r).forEach((day) => {
              if (r[day].meals[0].main != "") {
                anyMeal = true;
              }
            });
            if (!anyMeal) {
              this.week = {};
            } else {
              vm.week = r;
            }
          } else if (status === 404) {
            r = {};
          }
        }
      );
    },
    playBeep(f = 1400) {
      // Create an audio context
      const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
      // Create an oscillator
      const oscillator = audioCtx.createOscillator();
      // Set the frequency (in Hz) for the beep
      oscillator.frequency.setValueAtTime(f, audioCtx.currentTime); // A4 note
      oscillator.type = "sine"; // Sound wave type
      // Connect oscillator to destination (speaker)
      oscillator.connect(audioCtx.destination);
      // Play sound
      oscillator.start();
      // Stop sound after 0.2 seconds
      oscillator.stop(audioCtx.currentTime + 0.2);
    },
    openWebSocket() {
      console.log("opening socket");
      const url = `wss://s.rescanner.de/ws/${this.$router.currentRoute.value.name}`;
      if (this.webSocket) {
        this.webSocket.close();
      }
      this.webSocket = new WebSocket(url);

      this.webSocket.onmessage = (event) => {
        this.webSocketMessage = event.data;
        console.log("WebSocket message:", event.data);
        if (event.data == "keepalive") {
          this.lastKeepAlive = new Date();
        } else {
          let msg = JSON.parse(event.data);
          if (
            msg.type == "remaining" &&
            this.$route.query.monitor !== undefined
          ) {
            this.remaining = msg.data;
            return;
          }
          if (
            msg.type == "keepalive" ||
            (msg.data && msg.data.status == "keepalive")
          ) {
            this.lastKeepAlive = new Date();
          } else {
            this.handleResponse(msg.data, msg.status);
          }
        }
      };
      this.webSocket.onopen = (event) => {
        this.webSocket.send("keepalive");
      };
      if (this.kaInterval) {
        clearInterval(this.kaInterval);
      }
      this.kaInterval = setInterval(() => {
        this.webSocket.send("keepalive");
      }, 10000);
    },
    send(tag, type = "rfid") {
      API.checkRFID(
        {
          rfid: tag,
          project: this.$router.currentRoute.value.name,
          type: type,
        },
        (r, status) => {
          if (tag == "keepalive") {
            this.lastKeepAlive = new Date();
          } else {
            this.handleResponse(r, status);
          }
        }
      );
    },
    handleResponse(r, status) {
      this.displayMessage = r;
      this.blockReading = true;
      if (status == 200) {
        this.bg = "bg-green-300/90";
      } else {
        this.bg = "bg-red-300/90";
      }
      setTimeout(() => {
        this.displayMessage = {};
        this.blockReading = false;
      }, 6000);
    },
    startQRScan() {
      if (this.qrscan) {
        window.rescanner.stopQRScan();
        this.qrscan = false;
        return;
      }
      console.log("start scan");
      window.rescanner.startQRScan(this.$refs.video);
      this.qrscan = true;
    },
    reformatAndSendRFID(tag) {
      // tag = tag.match("[0-9A-z]*")
      // if (tag) tag = tag[0]
      if (this.blockReading || tag == this.lastTag) {
        return;
      }
      this.playBeep();
      this.lastTag = tag;
      let reversedHex = tag
        .replace(/\s/g, "")
        .match(/.{2}/g)
        .reverse()
        .join("");
      let decimalNumber = parseInt(reversedHex, 16).toString();
      if (decimalNumber.length < 10) decimalNumber = "0" + decimalNumber;
      console.log(decimalNumber);
      this.send(decimalNumber);
    },
    startEVKeepAlive() {
      setInterval(() => {
        this.send("keepalive");
      }, 10000);
    },
    updateRemainingMeals() {
      setInterval(() => {
        API.getRemainingMeals(this.$route.name, (r) => {
          if(r && r.data){
            this.remaining = r.data
          }
        });
      }, 10000);
    },
    monitorWebsocket() {
      setInterval(() => {
        if (new Date() - this.lastKeepAlive > 30000) {
          this.openWebSocket();
        }
      }, 10000);
    },
  },
  mounted() {
    if (this.$route.query.monitor !== undefined) {
      this.openWebSocket();
      this.monitorWebsocket();
      this.updateRemainingMeals();
    } else {
      this.startEVKeepAlive();
    }
    if (this.$route.query.rfid) {
      this.rfid = this.$route.query.rfid;
      send();
    }
    this.getMealPlan();
    if (!window.rescanner) {
      let vm = this;
      let rescannerLib = document.createElement("script");
      rescannerLib.setAttribute("src", "https://cdn.rescanner.de/rescanner.js");
      rescannerLib.onload = () => {
        window.rescanner.onNFCSuccess = (message) => {
          vm.reformatAndSendRFID(message);
        };
        window.rescanner.onQRSuccess = (message) => {
          vm.qrscan = false;
          vm.send(message);
        };
      };
      document.head.appendChild(rescannerLib);
    }
  },
};
</script>
<style scoped>
.key {
  width: 40px;
  height: 40px;
  margin: 5px;
  text-align: center;
  border: 1px solid #ccc;
  background: #eee;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.key:active {
  background: #ddd;
}

.key.wide {
  width: 80px;
}

.key.extra-wide {
  width: 120px;
}

.row {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>